
$(document).ready( function() {

    // Hero gallery
    $('.hero__gallery-content').slick({
      adaptiveHeight: true,
      arrows: false,
      autoplay: true,
      dots: false,
      draggable: false,
      fade: true,
      infinite: true,
      pauseOnHover: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 1000
    });


    // About us gallery
    $('.aboutus__gallery').slick({
      adaptiveHeight: true,
      arrows: true,
      dots: false,
      draggable: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    });

    // // Designer detail gallery
    // $('.detail-hero__gallery-content').slick({
    //   adaptiveHeight: false,
    //   arrows: true,
    //   autoplay: false,
    //   centerMode: true,
    //   dots: false,
    //   draggable: true,
    //   infinite: true,
    //   pauseOnHover: false,
    //   speed: 300,
    //   variableWidth: true,
    //   responsive: [
    //     {
    //       breakpoint: 640,
    //       settings: {
    //         arrows: false
    //       }
    //     }
    //   ]
    // });

    // // Projects gallery
    // $('.projects__gallery-content-for').slick({
    //   adaptiveHeight: false,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   arrows: false,
    //   fade: true,
    //   asNavFor: '.projects__gallery-content-nav',
    //   variableWidth: false,
    //   responsive: [
    //     {
    //       breakpoint: 640,
    //       settings: {
    //         dots: true
    //       }
    //     }
    //   ]
    // });
    // $('.projects__gallery-content-nav').slick({
    //   adaptiveHeight: false,
    //   arrows: false,
    //   slidesToShow: 8,
    //   slidesToScroll: 1,
    //   asNavFor: '.projects__gallery-content-for',
    //   dots: false,
    //   centerMode: false,
    //   focusOnSelect: true
    // });

});