$(function() {

  var $body = $('body');
  var $mainWrapper = $('.main-wrapper');
  var $modal = $('.search-modal');
  var $modalWrapper = $('.search-modal__wrapper', $modal);
  var $box = $('.search-modal__box', $modal);
  var $closeModal = $('.search-modal__close', $modal);
  var $list = $('.search-modal__list', $modal);
  var $filterPlace = $('.filters__place-value');

  var $heroBox = $('.hero__content-box');
  var $filtersPlaceBox = $('.filters__place-value');


  // Open the list clicking on the box
  $box.click(function() {
    $list.addClass('open');
  });

  // Set up value and close the list whene selecting an option
  $('.search-modal__item', $modal).each(function() {
    $(this).click(function() {
      var value = $(this).text();
      $box.text(value);

      if($body.hasClass('front-page')) {
        $heroBox.text(value);
      } else if($body.hasClass('arredatori-page')) {
        $filtersPlaceBox.text(value);
      }

      $box.addClass('selected');
      $list.removeClass('open');
    });
  });

  // Clicking outside the list to close it - stop propagation on children
  $modalWrapper.click(function(){
    $list.removeClass('open');
  }).children().click(function(e) {
    return false;
  });

  // Close the modal
  $closeModal.click(function() {
    $modal.removeClass('show');
    $body.removeClass('h-noscroll');
  });

  $filterPlace.click(function() {
    $list.addClass('open');
  });

  // Front page
  // if($mainWrapper.hasClass('front-page')) {

  //   // Open the modal
  //   $heroBox.click(function() {
  //     $body.addClass('h-noscroll');
  //     $modal.addClass('show');
  //   });

  // }

  // // Arredatori page - filter bar
  // if($mainWrapper.hasClass('arredatori-page') ||
  //    $mainWrapper.hasClass('outlet-page')) {

  //   // console.log("HERE");

  //   // Open the modal
  //   $filtersPlaceBox.click(function() {
  //     $body.addClass('h-noscroll');
  //     $modal.addClass('show');
  //   });

  // }

});