$(function() {

  var $mainWrapper = $('.main-wrapper');

  if($mainWrapper.hasClass('front-page')) {

    // Scroll down
    $('.hero__scroll').click(function(e) {
      TweenMax.to(window, 1, {scrollTo: {y: ".section__outlet-best", offsetY: 76}});
    });

  }

});