$(function() {

  var $body = $('body');
  var $mainWrapper = $('.main-wrapper');

  // Scroll down menu
  $('.detail-menu__link').click(function(e){
    e.preventDefault();
    var href = $(this).attr('href');
    TweenMax.to(window, 1, {scrollTo: {y: href, offsetY: 76}});
  });

  if($mainWrapper.hasClass('arredatori-dettaglio-page')) {
    if (matchMedia) {
      var mq = window.matchMedia("(min-width: 1200px)");
      mq.addListener(WidthChange);
      WidthChange(mq);
    }
  }


  function WidthChange(mq) {
    if (mq.matches) {

      var controller = null;

      controller = new ScrollMagic.Controller({addIndicators: false});

      // Scene Handler
      var scene = new ScrollMagic.Scene({
          triggerElement: ".arredatori-dettaglio__wrapper",
          duration: "365%",
          offset: -150,
          triggerHook: 0.12,
          reverse: true
      })
      .setPin(".section__hours.pinnable")
      .addTo(controller);

    } else {
        if (controller != null) {
            controller = controller.destroy(true);
        }
    }
  }

});