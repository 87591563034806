$(function() {

  var $body = $('body');
  var $reviewModal = $('.review-modal');
  var $outletModal = $('.outlet-modal');

  // Close behaviour for alla modals
  // $('.modal__close').click(function(e){
  //    e.preventDefault();
  //    var $modal = $(this).closest('.modal');
  //    $modal.removeClass('show');
  //    $body.removeClass('h-noscroll');
  // });

  // Review modal opening
  // $('.reviews__add-cta').click(function(e) {
  //   e.preventDefault();
  //   $reviewModal.addClass('show');
  //   $body.addClass('h-noscroll');
  // });

  // Review modal rating stars
  var $starList = $('.rate__star');
  var n = $starList.length;

  $starList.each(function() {
    var v = $(this).attr('data-attr');
    $(this).click(function(){
      $starList.each(function() {
        if($(this).index() <= (v - 1)) {
          $(this).addClass('full');
        } else {
          $(this).removeClass('full');
        }
      });
    });
  });

  // Outlet modal opening
  $('.outlet-card__cta').click(function(e) {
    e.preventDefault();
    $outletModal.addClass('show');
    $body.addClass('h-noscroll');
  });

});