$(function() {

  var $body = $('body');
  var $mainWrapper = $('.main-wrapper');

  // Front-page animations
  if($mainWrapper.hasClass('front-page')) {

    var ctrl = new ScrollMagic.Controller({addIndicators: false});

    // Hero section

    $heroContent = $('.hero__content');
    $heroLabel = $('.hero__content-label', $heroContent);
    $heroBox = $('.hero__content-box', $heroContent);
    $heroDescription = $('.hero__content-description', $heroContent);
    $heroCta = $('.hero__content-cta', $heroContent);
    $heroScroll = $('.hero__scroll');

    var tl = new TimelineLite();

    // TweenMax.set($heroContent, { autoAlpha: 1 });
    TweenMax.set([$heroContent, $heroScroll], { autoAlpha: 1 });

    tl.from($heroLabel, 1.5, {autoAlpha: 0}, .25)
      .from($heroBox, 1.5, {scale: .9, autoAlpha: 0,  ease: Power3.easeOut}, .5)
      .from($heroDescription, 1.5, {scale: .9, autoAlpha: 0,  ease: Power3.easeOut}, .75)
      .from($heroCta, 1.5, {autoAlpha: 0,  ease: Power3.easeOut}, 1)
      .from($heroScroll, 1.5, {autoAlpha: 0,  ease: Power3.easeOut}, 1.25);


    // Outlet section

    var ctrl2 = new ScrollMagic.Controller({addIndicators: false});

    $outletSection = $('.section__outlet-best');
    $outletImages = $('.outlet-best__image-item', $outletSection);
    $outletCta = $('.outlet-best__cta', $outletSection);
    $outletDescription = $('.outlet-best__description', $outletSection);

    TweenMax.set([$outletImages, $outletCta, $outletDescription], { autoAlpha: 0, scale: .9 });

    var tl2 = new TimelineLite({paused: true});

    tl2.delay(1.75).staggerTo($outletImages, 1.7, {scale: 1, autoAlpha: 1, ease: Power3.easeOut}, .7)
       .to($outletCta, 1.7, {scale: 1, autoAlpha: 1,  ease: Power3.easeOut}, 1.65)
       .to($outletDescription, 1.7, {scale: 1, autoAlpha: 1,  ease: Power3.easeOut}, 2.25);

    var sc2 = new ScrollMagic.Scene({
              triggerElement: $outletSection[0]
            })
            .triggerHook(0.5)
            .on('enter', function(e){
               tl2.play();
            })
            .addTo(ctrl2);

    // Whys section

    var ctrl3 = new ScrollMagic.Controller({addIndicators: false});

    $whysSectionBtm = $('.whys-bottom');
    $whysItems = $('.whys-bottom__item', $whysSectionBtm);

    TweenMax.set([$whysSectionBtm, $whysItems], { autoAlpha: 1 });

    var tl3 = new TimelineLite();

    tl3.delay(1.25)
       .staggerFrom($whysItems, 1.65, {scale: .9, autoAlpha: 0,  ease: Power3.easeOut}, .5);

    var sc3 = new ScrollMagic.Scene({
              triggerElement: $whysSectionBtm[0],
              triggerHook: .5
            })
            .reverse(false)
            .setTween(tl3)
            .addTo(ctrl3);
  }


});
