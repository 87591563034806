$(function() {

  var $body = $('body');
  var $filtersRooms = $('.filter__rooms-item');
  var $filtersButton = $('.filters__button');
  var $filtersRight = $('.filters__right');

  // Rooms filters
  $filtersRooms.click(function() {
    $(this).toggleClass('active');
  });

  // Responsive filters menu
  $filtersButton.click(function() {
    $(this).toggleClass('active');
    $body.toggleClass('h-noscroll');
    $filtersRight.toggleClass('open');
  });


});