$(function() {

  var $body = $('body');
  var $header = $('.header');
  var $menu = $('.header__menu', $header)
  var $menuButton = $('.header__menu-icon', $header);
  var $mainWrapper = $('.main-wrapper');

  if($mainWrapper.hasClass('front-page')) {

    // var controller = new ScrollMagic.Controller({addIndicators: true});
    var controller = new ScrollMagic.Controller({});

    // Sticky menu
    new ScrollMagic.Scene({
          triggerElement: "body",
          triggerHook: '0',
          offset: '5'})
          .on('enter', function(){
            $header.addClass('sticky');
          })
          .on('leave', function(){
            $header.removeClass('sticky');
          })
          .addTo(controller);
  }

  // Responsive menu open and close
  $menuButton.click(function() {
    $body.toggleClass('h-noscroll');
    $menu.toggleClass('open-menu');
    $(this).toggleClass('close-icon');
  });

});