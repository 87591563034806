$(function() {

  var $body = $('body');
  var $mainWrapper = $('.main-wrapper');

  if($mainWrapper.hasClass('arredatori-page')) {
    if (matchMedia) {
      var mq = window.matchMedia("(min-width: 1200px)");
      mq.addListener(WidthChange);
      WidthChange(mq);
    }
  }


  function WidthChange(mq) {
    if (mq.matches) {

      var controller = null;

      controller = new ScrollMagic.Controller({addIndicators: false});

      // Scene Handler
      var scene = new ScrollMagic.Scene({
          triggerElement: ".designer-results__list",
          duration: "100%",
          offset: 10,
          triggerHook: 0.12,
          reverse: true
      })
      .setPin(".section__help")
      .addTo(controller);

    } else {
        if (controller != null) {
            controller = controller.destroy(true);
        }
    }
  }

});